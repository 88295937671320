<script>
    import axios from "axios";
    import { closeModal, closeAllModals, openModal, modals } from "svelte-modals";
    import Swal from "sweetalert2";
    import { _ } from "../services/i18n"
    export let id;
    export let model;
  
   
 
  
//     async function DeleteModal(id) {
   
//           var data = JSON.stringify({
//             id: id,
//           });

  //     async function DeleteModal(id) {

  //           var data = JSON.stringify({
  //             id: id,
  //           });

  //           var config = {
  //             method: "delete",
  //             url: "https://staging.ws.beta.machine-editique.cloud.geoprod.com/" +"/editique_pdf/delete_modal",
  //             headers: {
  //               "Content-Type": "application/json",
  //             },
  //             data: data,
  //           };

  //           axios(config)
  //             .then((res)=>{
  //               closeModal()
  //             Swal.fire({
  //                 icon: "success",
  //                 title: "supprimé avec succés",
  //                 text: "supprimé avec succés",
  //               }).then(()=> window.location.reload())
  //             }

  //                 )
  //             .catch(function (error) {
  //               closeModal()
  //               console.log(error);
  //             });

  //   }
  function DeleteModal(wk) {
    var config = {
      method: "delete",
      url: "https://staging.ws.beta.machine-editique.cloud.geoprod.com/" +"/workspace/delete",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
      data: {
        id: wk,
      },
    };

    axios(config)
      .then(function (response) {
        closeModal()
            Swal.fire({
                icon: "success",
                text: $_('workspace.alertDelete'),
                // text: $_('workspace.alertDelete'),
              }).then(()=> window.location.reload())
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire({
          icon: "error",
        
          text: $_('workspace.Échec de suppression du modèle'),
        });
      });
  }
</script>

<!-- {#if isOpen} -->
<div role="dialog" class="modal">
  <div class="contents">
    <div>
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

      <div class="fixed inset-0 z-10 ">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
         
              <div
                class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg bg-[url('assets/images/bg.png')]"
              >
                <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div class="sm:flex sm:items-start">
                   
                    <div class="mt-3 text-center sm:mt-0 sm:ml-4 ">
                      <h3
                        style="color:#945FAF;font-size:1.3rem;font-weight:500;font-family:Roboto"
                        id="modal-title"
                      >
                      {$_('workspace.supprimerProjet')}
                  
                      </h3>
                      <div class="m-6">
                        <label
                          for="default-input"
                          style="color: #7E7E7E; font-size:1rem;font-weight:500;font-family:Roboto"
                          >  {$_('workspace.textSupprimerProjet')}</label
                        >
                       
                      </div>
                     
                <div
                  class="  pb-6 text-center "
                >
                  <button
                    type="button"
                    on:click={closeModal}
                    style="background-color:#7E7E7E; width:8rem; padding-block:0.5rem;color:white;border-radius:0.5rem"
                    > {$_('workspace.non')}</button
                  >
                  <button
                    type="button"
                    style="background-color:#945FAF; width:8rem;padding-block:0.5rem;color:white;border-radius:0.5rem"
                    on:click={DeleteModal(id)}
                    >{$_('workspace.oui')}</button
                  >
                
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- {/if} -->
<style>.modal {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  /* allow click-through to backdrop */
  pointer-events: none;
}

.contents {
  min-width: 240px;
  border-radius: 6px;
  padding: 16px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  pointer-events: auto;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL1N1cHByZXNpb25Xa01vZGFsLnN2ZWx0ZSJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDQTtFQUNFLGtCQUFrQjtFQUNsQixNQUFNO0VBQ04sU0FBUztFQUNULFFBQVE7RUFDUixPQUFPO0VBQ1Asa0JBQWtCO0VBQ2xCLGFBQWE7RUFDYix1QkFBdUI7RUFDdkIsbUJBQW1CO0VBQ25CLG9DQUFvQztFQUNwQyxvQkFBb0I7QUFDdEI7O0FBRUE7RUFDRSxnQkFBZ0I7RUFDaEIsa0JBQWtCO0VBQ2xCLGFBQWE7RUFDYixpQkFBaUI7RUFDakIsYUFBYTtFQUNiLHNCQUFzQjtFQUN0Qiw4QkFBOEI7RUFDOUIsb0JBQW9CO0FBQ3RCIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL1N1cHByZXNpb25Xa01vZGFsLnN2ZWx0ZSIsInNvdXJjZXNDb250ZW50IjpbIlxuLm1vZGFsIHtcbiAgcG9zaXRpb246IGFic29sdXRlO1xuICB0b3A6IDA7XG4gIGJvdHRvbTogMDtcbiAgcmlnaHQ6IDA7XG4gIGxlZnQ6IDA7XG4gIHRleHQtYWxpZ246IGNlbnRlcjtcbiAgZGlzcGxheTogZmxleDtcbiAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG4gIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gIC8qIGFsbG93IGNsaWNrLXRocm91Z2ggdG8gYmFja2Ryb3AgKi9cbiAgcG9pbnRlci1ldmVudHM6IG5vbmU7XG59XG5cbi5jb250ZW50cyB7XG4gIG1pbi13aWR0aDogMjQwcHg7XG4gIGJvcmRlci1yYWRpdXM6IDZweDtcbiAgcGFkZGluZzogMTZweDtcbiAgYmFja2dyb3VuZDogd2hpdGU7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG4gIGp1c3RpZnktY29udGVudDogc3BhY2UtYmV0d2VlbjtcbiAgcG9pbnRlci1ldmVudHM6IGF1dG87XG59XG4iXX0= */</style>
