<script>
  import axios from "axios";
  import { onMount } from "svelte";
  import { closeModal, closeAllModals, openModal, modals } from "svelte-modals";
  import { userdata } from "../store/store";
  import SimpleCrypto from "simple-crypto-js"
    import { navigate } from "svelte-routing";
    import ImgEncoder from 'svelte-image-encoder';
    import Swal from "sweetalert2";

import { _ } from "../services/i18n"
  export let isOpen;
  export let title;
  export let message;
  export let onOpenAnother;
  let loader = false;
  let stackIndex = modals.length;
  var Base64;
  var file;
  let workspacename = "";
  let messageErr = "";
  const uploadImage = (e) => {
    const files = e.target.files || (e.dataTransfer && e.dataTransfer.files);
    file = files[0];
    var imgReader = new FileReader();

    imgReader.readAsDataURL(file);
    imgReader.onloadend = function () {
      Base64 = imgReader.result;
    };
  };

  const AddWorkspace = (e) => {
  if(workspacename==="" ){
    messageErr=$_('workspace.Veuillez introduire le nom de votre projet')
  }
  else{
    loader=true
    e.preventDefault();
    let simpleCrypto = new SimpleCrypto("s6d1f53sdq1f351sd31f3sdq315f135sd1f");
    let usid = localStorage.getItem("_");
    let uid = simpleCrypto.decrypt(usid);
    axios
      .post("https://staging.ws.beta.machine-editique.cloud.geoprod.com/" +"/workspace/create", {
            user_id: uid,
            name: workspacename,
            logo_b64: Base64,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("access_token"),
            },
          }
)
      .then((res) => {
       loader=false
        closeModal();
        // window.location.href = "/Workspaces"
        Swal.fire({
                icon: "success",
                title:  $_('workspace.Projet crée avec succés'),
               
              }).then(()=> window.location.href = "/Workspaces")
        })

        .catch((err) =>
        {
      
      Swal.fire({
          icon: "error",
          text:  $_('workspace.Échec de création de projet'),
        })
      }
        );
      navigate();
    }
  };
</script>

{#if isOpen}
  <div role="dialog" class="modal relative">
    <div class="contents ">
      <h2>{title}</h2>
      <p>{message}</p>
      <div
        class="relative z-10"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />

        <div class="fixed inset-0 z-10 ">
          <div
            class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
          >
            <div
              class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg bg-[url('assets/images/bg.png')]"
            >
              <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div class=" sm:items-start">
                  <div class="mt-3 text-center sm:mt-0 sm:ml-4 ">
                    <h3
                      class="text-lg font-medium leading-6 text-gray-900"
                      id="modal-title"
                    >
                    {$_('workspace.createProject')}
                
                    </h3>
                    <div class="m-6">
                      <label
                        for="default-input"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300 "
                        >
                        {$_('workspace.nomProject')}
                        </label
                      >
                      <input
                        type="text"
                        id="default-input"
                        bind:value={workspacename}
                        class="bg-gray-50 p-4 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 h-10"
                      />
                    </div>
                    <div style="margin-top:2.5rem;text-align:center;margin-bottom:3rem" >
                      <p style="color:#7E7E7E;font-weight:500;font-size:16px  ">{$_('workspace.imageProject')}</p>
                     {#if !file}
                      <div style="position: relative; margin-top:1rem ; left:35%">
                        <svg width="131" height="131" viewBox="0 0 131 131" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="65.5" cy="65.5" r="65.5" fill="#D9D9D9"/>
                        </svg>
                      </div>
                        <div style="position: absolute; top:20rem; left:250px; " >
                          <label for="image">
                            <svg
                              style="cursor:pointer"
                              width="46"
                              height="46"
                              viewBox="0 0 46 46"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="23"
                                cy="23"
                                r="22.75"
                                fill="#FAF7FC"
                                stroke="#AFAFAF"
                                stroke-width="0.5"
                              />
                              <path
                                d="M10.7736 32.6815L10.7469 32.7081C10.3869 31.9215 10.1602 31.0281 10.0669 30.0415C10.1602 31.0148 10.4136 31.8948 10.7736 32.6815ZM19.3349 21.1748C20.1765 21.1748 20.9837 20.8405 21.5788 20.2453C22.1739 19.6502 22.5082 18.8431 22.5082 18.0015C22.5082 17.1598 22.1739 16.3527 21.5788 15.7576C20.9837 15.1625 20.1765 14.8281 19.3349 14.8281C18.4933 14.8281 17.6861 15.1625 17.091 15.7576C16.4959 16.3527 16.1616 17.1598 16.1616 18.0015C16.1616 18.8431 16.4959 19.6502 17.091 20.2453C17.6861 20.8405 18.4933 21.1748 19.3349 21.1748Z"
                                fill="#945FAF"
                              />
                              <path
                                d="M28.92 10H17.7467C12.8933 10 10 12.8933 10 17.7467V28.92C10 30.3733 10.2533 31.64 10.7467 32.7067C11.8933 35.24 14.3467 36.6667 17.7467 36.6667H28.92C33.7733 36.6667 36.6667 33.7733 36.6667 28.92V17.7467C36.6667 12.8933 33.7733 10 28.92 10ZM34.4933 24C33.4533 23.1067 31.7733 23.1067 30.7333 24L25.1867 28.76C24.1467 29.6533 22.4667 29.6533 21.4267 28.76L20.9733 28.3867C20.0267 27.56 18.52 27.48 17.4533 28.2L12.4667 31.5467C12.1733 30.8 12 29.9333 12 28.92V17.7467C12 13.9867 13.9867 12 17.7467 12H28.92C32.68 12 34.6667 13.9867 34.6667 17.7467V24.1467L34.4933 24Z"
                                fill="#945FAF"
                              />
                            </svg>
                          </label>
                          <input
                            id="image"
                            type="file"
                            class="hidden"
                            accept="image/*"
                            on:change={uploadImage}
                          />
                        </div>
                      {/if}
                      {#if file}
                        <img
                          alt="image"
                          src={Base64}
                          class="rounded-full "
                          style="left:35%;position: relative; margin-top:1rem;width:131px;height:131px"
                        />
                        <div
                          style="position: absolute; top:20rem; left:250px; "
                        >
                          <label for="image">
                            <svg
                              style="cursor:pointer"
                              width="46"
                              height="46"
                              viewBox="0 0 46 46"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="23"
                                cy="23"
                                r="22.75"
                                fill="#FAF7FC"
                                stroke="#AFAFAF"
                                stroke-width="0.5"
                              />
                              <path
                                d="M10.7736 32.6815L10.7469 32.7081C10.3869 31.9215 10.1602 31.0281 10.0669 30.0415C10.1602 31.0148 10.4136 31.8948 10.7736 32.6815ZM19.3349 21.1748C20.1765 21.1748 20.9837 20.8405 21.5788 20.2453C22.1739 19.6502 22.5082 18.8431 22.5082 18.0015C22.5082 17.1598 22.1739 16.3527 21.5788 15.7576C20.9837 15.1625 20.1765 14.8281 19.3349 14.8281C18.4933 14.8281 17.6861 15.1625 17.091 15.7576C16.4959 16.3527 16.1616 17.1598 16.1616 18.0015C16.1616 18.8431 16.4959 19.6502 17.091 20.2453C17.6861 20.8405 18.4933 21.1748 19.3349 21.1748Z"
                                fill="#945FAF"
                              />
                              <path
                                d="M28.92 10H17.7467C12.8933 10 10 12.8933 10 17.7467V28.92C10 30.3733 10.2533 31.64 10.7467 32.7067C11.8933 35.24 14.3467 36.6667 17.7467 36.6667H28.92C33.7733 36.6667 36.6667 33.7733 36.6667 28.92V17.7467C36.6667 12.8933 33.7733 10 28.92 10ZM34.4933 24C33.4533 23.1067 31.7733 23.1067 30.7333 24L25.1867 28.76C24.1467 29.6533 22.4667 29.6533 21.4267 28.76L20.9733 28.3867C20.0267 27.56 18.52 27.48 17.4533 28.2L12.4667 31.5467C12.1733 30.8 12 29.9333 12 28.92V17.7467C12 13.9867 13.9867 12 17.7467 12H28.92C32.68 12 34.6667 13.9867 34.6667 17.7467V24.1467L34.4933 24Z"
                                fill="#945FAF"
                              />
                            </svg>
                          </label>
                          <input
                            id="image"
                            type="file"
                            class="hidden"
                            accept="image/*"
                            on:change={uploadImage}
                          />
                        </div>
                      {/if}
                    </div>
                  </div>
                </div>
                <p style="color:#FE2424;font-size:14px">
                  {messageErr !== "" ? messageErr : ""}
                </p>
              </div>

              <div class="  pb-6 text-center ">
                <button
                  type="button"
                  on:click={closeModal}
                  class="annulerBtn inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  >{$_('workspace.annuler')}</button
                >
                <span
                  disabled={loader}
                  type="button"
                  style="cursor: {!loader ? 'pointer' : ''};"
                  class=" confirmBtn mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  on:click={AddWorkspace}
                  >      {loader ? "..." : $_('workspace.confirmer') }
                  </span
                >
                 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
{/if}

<style>.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  /* allow click-through to backdrop */
  pointer-events: none;
}

.contents {
  min-width: 240px;
  border-radius: 6px;
  padding: 16px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  pointer-events: auto;
}

h2 {
  text-align: center;
  font-size: 24px;
}

p {
  text-align: center;
  margin-top: 16px;
}

.confirmBtn {
  background-color: #945faf;
  color: white;
}

.annulerBtn {
  background-color: #7e7e7e;
}

.centerClass {
  text-align: -webkit-center;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL01vZGFsLnN2ZWx0ZSJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDQTtFQUNFLGVBQWU7RUFDZixNQUFNO0VBQ04sU0FBUztFQUNULFFBQVE7RUFDUixPQUFPO0VBQ1Asa0JBQWtCO0VBQ2xCLGFBQWE7RUFDYix1QkFBdUI7RUFDdkIsbUJBQW1CO0VBQ25CLG9DQUFvQztFQUNwQyxvQkFBb0I7QUFDdEI7O0FBRUE7RUFDRSxnQkFBZ0I7RUFDaEIsa0JBQWtCO0VBQ2xCLGFBQWE7RUFDYixpQkFBaUI7RUFDakIsYUFBYTtFQUNiLHNCQUFzQjtFQUN0Qiw4QkFBOEI7RUFDOUIsb0JBQW9CO0FBQ3RCOztBQUVBO0VBQ0Usa0JBQWtCO0VBQ2xCLGVBQWU7QUFDakI7O0FBRUE7RUFDRSxrQkFBa0I7RUFDbEIsZ0JBQWdCO0FBQ2xCOztBQUNBO0VBQ0UseUJBQXlCO0VBQ3pCLFlBQVk7QUFDZDs7QUFDQTtFQUNFLHlCQUF5QjtBQUMzQjs7QUFDQTtFQUNFLDBCQUEwQjtBQUM1QiIsImZpbGUiOiJzcmMvY29tcG9uZW50cy9Nb2RhbC5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcbi5tb2RhbCB7XG4gIHBvc2l0aW9uOiBmaXhlZDtcbiAgdG9wOiAwO1xuICBib3R0b206IDA7XG4gIHJpZ2h0OiAwO1xuICBsZWZ0OiAwO1xuICB0ZXh0LWFsaWduOiBjZW50ZXI7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGp1c3RpZnktY29udGVudDogY2VudGVyO1xuICBhbGlnbi1pdGVtczogY2VudGVyO1xuICAvKiBhbGxvdyBjbGljay10aHJvdWdoIHRvIGJhY2tkcm9wICovXG4gIHBvaW50ZXItZXZlbnRzOiBub25lO1xufVxuXG4uY29udGVudHMge1xuICBtaW4td2lkdGg6IDI0MHB4O1xuICBib3JkZXItcmFkaXVzOiA2cHg7XG4gIHBhZGRpbmc6IDE2cHg7XG4gIGJhY2tncm91bmQ6IHdoaXRlO1xuICBkaXNwbGF5OiBmbGV4O1xuICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICBqdXN0aWZ5LWNvbnRlbnQ6IHNwYWNlLWJldHdlZW47XG4gIHBvaW50ZXItZXZlbnRzOiBhdXRvO1xufVxuXG5oMiB7XG4gIHRleHQtYWxpZ246IGNlbnRlcjtcbiAgZm9udC1zaXplOiAyNHB4O1xufVxuXG5wIHtcbiAgdGV4dC1hbGlnbjogY2VudGVyO1xuICBtYXJnaW4tdG9wOiAxNnB4O1xufVxuLmNvbmZpcm1CdG4ge1xuICBiYWNrZ3JvdW5kLWNvbG9yOiAjOTQ1ZmFmO1xuICBjb2xvcjogd2hpdGU7XG59XG4uYW5udWxlckJ0biB7XG4gIGJhY2tncm91bmQtY29sb3I6ICM3ZTdlN2U7XG59XG4uY2VudGVyQ2xhc3Mge1xuICB0ZXh0LWFsaWduOiAtd2Via2l0LWNlbnRlcjtcbn1cbiJdfQ== */</style>
